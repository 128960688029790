.card_ {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(24, 24, 24, 0.7);
  max-width: 25%;
  max-height: 400px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.card_ a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.card__img {
  position: absolute;
  width: 100%;
  min-height: 100%;
  transition: 0.5s;
  box-shadow: -20px 20px 10px rgba(0, 0, 0, 0.2);
  border: 6px solid black;
}

.card--transform {
  transform: rotate(-30deg) skew(25deg) scale(1.2);
  transition: 0.5s;
}

.card_:hover .card__img:nth-child(1) {
  transform: translate(20px, -20px);
  opacity: 0.4;
}
.card_:hover .card__img:nth-child(2) {
  transform: translate(40px, -40px);
  opacity: 0.6;
}
.card_:hover .card__img:nth-child(3) {
  transform: translate(60px, -60px);
  opacity: 0.8;
}
.card_:hover .card__img:nth-child(4) {
  transform: translate(80px, -80px);
  opacity: 1;
}

@media (max-width: 1000px) {
  .card_ {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(24, 24, 24, 0.7);
    max-width: 100%;
    max-height: 350px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }

  .card__img {
    position: absolute;
    width: 100%;
    min-height: 100%;
    transition: 0.5s;
    box-shadow: -20px 20px 10px rgba(0, 0, 0, 0.2);
    border: 6px solid black;
  }
}
