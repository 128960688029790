.brandlogo__img {
  max-width: 400px;
  max-height: 500px;
  width: 100%;
  height: 100%;
  animation-duration: 3s;
}
.title__info h6 {
 letter-spacing: 3px;
 color: #777;
}

@media (max-width: 767px) {
  .brandlogo {
    position: absolute !important;
    margin: auto;
    left: 0;
    top: 5%;
    z-index: 0;
  }

  .brandlogo__img {
    position: relative;
    left: 15%;
    top: 30%;
    width: 100%;
    height: 100%;
    max-width: 320px;
    max-height: 500px;
    animation-duration: 3s;
    opacity: 0.4;
    z-index: 1;
  }

  .title__info {
    z-index: 2;
    margin-top: 150px;
  }
  .title__info h2 {
    font-size: 20px;
  }
  .title__info h1 {
    font-size: 35px !important;
  }
  .title__info h6 {
    font-size: 14px;
    letter-spacing: 1px;
  }
}
