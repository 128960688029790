.bubble__canvas canvas {
  position: relative;
  left: 0;
  width: 100vw !important;
  height: 100vh !important;
  overflow: visible !important;
  /* margin-bottom: -10px; */
  opacity: 0.3;
  clip-path: polygon(100% 0, 100% 48%, 49% 100%, 0 46%, 0 0);
}
@media (max-width: 1500px) {
  .bubble__canvas canvas {
    left: -100px;
  }
}
@media (max-width: 1000px) {
  .bubble__canvas {
    display: none;
  }
}
