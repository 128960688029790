@import url('https://fonts.googleapis.com/css?family=Questrial');
[data-reactroot] {
  height: 100% !important;
}
* {
  font-family: 'Questrial', sans-serif;
  margin: 0;
  padding: 0;
  color: #fff;
}

html {
  height: 100% !important;
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: Questrial, sans-serif;
  width: 100%;
  height: 100%;
}

.App {
  background: #222;
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  overflow-x: hidden;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
  color: #61dafb;
}
.Rails-logo {
  animation: Rails-logo-tweak infinite 10s linear;
  height: 40vmin;
  pointer-events: none;
  color: #61dafb;
}

.canvas__display--none {
  display: none;
}

.formInput,
.formInput:focus,
.formInput:active:focus,
.formInput.active:focus,
.formInput.focus,
.formInput:active.focus,
.formInput.active.focus {
  outline: none !important;
  box-shadow: none !important;
}
#root {
  width: 100% !important;
  height: 100% !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes Rails-logo-tweak {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(10deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes changeColor {
  from {
    opacity: 0.1;
    filter: grayscale(100);
  }
  to {
    opacity: 1;
    filter: grayscale(0);
  }
}

@keyframes changeRoute {
  0% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(100%);
  }
}
