.experiment {
  position: relative;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  max-width: 50vw;
  max-height: 800px;
  height: 150px;
  width: 100vw;
  padding: 0;
  margin-left: -50px;
}

@media (max-width: 1600px) {
  .experiment {
    max-width: 50vw;
    margin-left: 50px;
  }
}

@media (max-width: 1350px) {
  .experiment {
    margin-left: 100px;
  }
}

@media (max-width: 1000px) {
  .experiment {
    position: relative;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
    height: 500vh;
    flex-direction: column;
  }
}
