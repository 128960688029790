.table tbody td, .table tbody th {
  padding: 0.5rem;
}

.col.table-container {
  background: rgba(0, 0, 0, 0.1);
  padding: 50px;
  max-width: 100%;
  box-sizing: border-box;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
}
hr {
  width: 40px;
  background: #08fdd8;
}

td {
  position: relative;
  padding-left: 30px !important;
}

td::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  margin-left: 10px !important;
  width: 15px;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.25);
}

td.empty::before {
  background: transparent;
}

.logo-images-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
}

@media (max-width: 1225px) {
  td::before {
    display: none;
  }

  td {
    padding-left: 10px !important;
  }
}

@media (max-width: 770px) {
  .logo-images-container {
    height: 0;
  }
  .App-logo {
    position: absolute;
    opacity: 0.2;
    top: -300px;
    z-index: 1;
  }
  .Rails-logo {
    position: absolute;
    opacity: 0.3;
    top: -300px;
    left: 100%;
    z-index: 1;
  }

  .text-glow {
    text-shadow: #fff 0 0 12px;
    position: relative;
    z-index: 9999;
  }

  h1 {
    font-size: 40px !important;
  }

  p {
    font-size: 14px !important;
  }
  .col.table-container {
    padding: 20px;
  }
  .table tbody td, .table tbody th {
    padding: 0.2rem;
    white-space: nowrap;
  }
}
