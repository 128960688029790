.contact-title {
  font-size: 72px;
  color: #08fdd8;
  opacity: 0.9;
}

#buttons-container {
  display: flex;
  gap: 8px;
  flex-direction: column;
}

a.card, .card {
  border: 1px solid rgba(255,255,255, 0.1);
  background: transparent;
  padding: 10px 14px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  max-width: 600px;
}

a.card i, a.card span {
  color: white;
}

a.card i {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

a.card:hover, .card:hover {
  cursor: pointer;
  text-decoration: none;
  border: 1px solid rgba(255,255,255, 0.4);
}
